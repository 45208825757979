<template>
  <div class="lsg-page lsg-trade__page trade_page">
    <div v-if="!leftSwitcher" class="trade_page__left trade_left">
      <div class="trade_left__head trade_head">
        <div class="trade_header__row">
          <h1 class="lsg-page-header">
            <a @click="$router.go(-1)">
              <svg xmlns="http://www.w3.org/2000/svg" width="20.243" height="13.501" viewBox="0 0 20.243 13.501">
                <path id="Icon_ionic-ios-arrow-round-back" data-name="Icon ionic-ios-arrow-round-back" d="M15.216,11.51a.919.919,0,0,1,.007,1.294l-4.268,4.282H27.218a.914.914,0,0,1,0,1.828H10.955L15.23,23.2a.925.925,0,0,1-.007,1.294.91.91,0,0,1-1.287-.007L8.142,18.647h0a1.026,1.026,0,0,1-.19-.288.872.872,0,0,1-.07-.352.916.916,0,0,1,.26-.64l5.794-5.836A.9.9,0,0,1,15.216,11.51Z" transform="translate(-7.882 -11.252)"/>
              </svg>
            </a>
            <template>{{ title }}</template>
          </h1>
          <div class="trade_head__action"></div>
        </div>
      </div>
      <div class="trade_left__content trade_content">
        <div class="trade_content__item accordion__item">
          <div class="trade_content__info accordion__content">
            <table class="table trade_content__table">
              <tbody>
              <tr>
                <td>Наименование</td>
                <td><input type="text" v-model="createFields.name"></td>
              </tr>
              <tr>
                <td>Тип имущества</td>
                <td>
                  <select v-model="createFields.propType">
                    <option
                      v-for="(item, mIndex) in propTypeSelect"
                      :key="mIndex"
                      :value="item.value"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                </td>
              </tr>
              <tr>
                <td>Стоимость</td>
                <td><input type="number" v-model.number="createFields.price"></td>
              </tr>
              <tr>
                <td>Аванс</td>
                <td><input type="number" v-model.number="createFields.prepaid"></td>
              </tr>
              <tr>
                <td>Аванс(%)</td>
                <td><input type="number" v-model.number="prepaidProc"></td>
              </tr>
              <tr>
                <td>Срок лизинга</td>
                <td><input type="number" v-model.number="createFields.duration" class="input_auto"></td>
              </tr>
              <tr>
                <td>Ставка привлечения</td>
                <td><input type="number" v-model.number="createFields.rate"></td>
              </tr>
              <tr>
                <td>Комиссия менеджера</td>
                <td><input type="number" v-model.number="createFields.managerCom" class="input_auto"></td>
              </tr>
              <tr>
                <td>Комиссия агента</td>
                <td><input type="number" v-model.number="createFields.agentCom" class="input_auto"></td>
              </tr>
              <tr>
                <td>Вид графика</td>
                <td>
                  <select v-model="createFields.graphType">
                    <option
                      v-for="(item, mIndex) in graphTypeSelect"
                      :key="mIndex"
                      :value="item.value"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                </td>
              </tr>
              <tr v-if="createFields.graphType !== 'annuity'">
                <td>Сила наклона</td>
                <td><input type="range" min="0" max="300" step="1" v-model.number="createFields.strength" style="padding:0;" class="input_auto"></td>
              </tr>
              <tr v-if="createFields.graphType === 'annuityStair'">
                <td>Месяцев в ступени</td>
                <td><input type="range" min="0" max="4" step="1" v-model.number="createFields.stair" style="padding:0;" class="input_auto"></td>
              </tr>
              </tbody>
            </table>
            <a href="#" class="button" style="margin-left: 5px" @click="downloadCalc('d669589a-71ad-11ea-80bf-00259047d1e4')">Оферта в PDF</a>
          </div>
        </div>
      </div>
    </div>
    <div class="trade_left__switch" @click="leftSwitcher = !leftSwitcher">
      <div><span></span><span></span></div>
    </div>
    <ApolloQuery
      v-if="input"
      :query="require('@/graphql/queries/TryCalc.gql')"
      :variables="{ input }"
      class="tr_actions trade_page__right"
    >
      <div slot-scope="{ result: { loading, error, data } }" class="comment_notes">
        <div v-if="error" class="messages apollo error">{{ error.message }}</div>
        <div v-else-if="data && data.tryCalc" class="comment_notes__item">
          <div class="grid_container_column">
            <div class="comment_notes__inner">
              <div class="comment_notes__head comment_header">Сумма договора</div>
              <div class="comment_notes__body comment_body">{{ prettifyPrice(data.tryCalc.summ) }} руб.</div>
            </div>
            <div class="comment_notes__inner">
              <div class="comment_notes__head comment_header">Минимальная ставка</div>
              <div class="comment_notes__body comment_body">{{ prettifyPrice(data.tryCalc.minRate) }} %</div>
            </div>
            <div class="comment_notes__inner">
              <div class="comment_notes__head comment_header">Максимальная комиссия</div>
              <div class="comment_notes__body comment_body">{{ prettifyPrice(data.tryCalc.maxAgent) }} ({{ prettifyPrice(Number(data.tryCalc.maxAgent)/2) }}) %</div>
            </div>
          </div>
          <div class="grid_container_row">
            <div v-for="cLine in data.tryCalc.graph" :key="cLine.lineNo" class="comment_notes__inner">
              <div class="comment_notes__head comment_header">{{ cLine.lineNo === 0 ? 'Аванс' : `${ cLine.lineNo } платёж` }}</div>
              <div class="comment_notes__body comment_body">{{ prettifyPrice(cLine.plan) }} руб.</div>
            </div>
          </div>
        </div>
      </div>
    </ApolloQuery>
  </div>
</template>

<script>
import UPDATE_CALC from '@/graphql/mutations/UpdateCalc.gql'
import GraphQLHelper from '@/helpers/GraphQLHelper'

const _graphQlHelper = new GraphQLHelper()

export default {
  name: 'CalculatorPage',
  data: () => {
    return {
      title: 'Расчётный калькулятор',
      links: [
        {
          name: 'Главная',
          route: 'home'
        },
        {
          name: 'Сделки',
          route: 'trades'
        },
        {
          name: 'Калькулятор расчетный'
        }
      ],
      createFields: {
        name: 'Тестовое имущество',
        price: 5000000,
        rate: 12,
        prepaid: 520000,
        duration: 20,
        managerCom: 0,
        agentCom: 0,
        strength: 0,
        stair: 0,
        graphType: 'annuity',
        propType: 'light'
      },
      graphTypeSelect: [{
        value: 'annuity',
        name: 'Равномерный'
      }, {
        value: 'drawdown',
        name: 'Убывающий'
      }, {
        value: 'annuityStair',
        name: 'Равномерный | Лестница'
      }],
      propTypeSelect: [{
        value: 'light',
        name: 'Легковой автотранспорт'
      }, {
        value: 'lightcommercial',
        name: 'Легкий коммерческий автотранспорт'
      }, {
        value: 'commercial',
        name: 'Коммерческий автотранспорт'
      }, {
        value: 'special',
        name: 'Спец техника'
      }, {
        value: 'equipment',
        name: 'Оборудование'
      }],
      leftSwitcher: false
    }
  },
  methods: {
    prettifyPrice (price) {
      return Number(price).toLocaleString()
    },
    async downloadCalc (calcId) {
      let currentInput = {
        id: calcId,
        leadId: '5e807e742f07c76dd7a473ad',
        ...this.createFields
      }

      await this.$apollo.mutate({
        mutation: UPDATE_CALC,
        variables: {
          input: currentInput
        },
        update: (store, { data }) => {
          if (data) {
            window.open(`${process.env.VUE_APP_HTTP}/download?type=calc&name=Calc&id=${calcId}`, '_blank')
          }
        }
      }).catch(error => {
        _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
          this.$notify({
            group: 'lsg-notify',
            text: item
          })
        })
      })
    }
  },
  computed: {
    prepaidProc: {
      get () {
        return 100 * this.createFields.prepaid / this.createFields.price || 0
      },
      set (newValue) {
        this.createFields.prepaid = this.createFields.price * newValue / 100 || 0
      }
    },
    input () {
      if (
        this.createFields.duration === 0 ||
        this.createFields.price === 0 ||
        this.createFields.prepaid === 0) return null
      return {
        ...this.createFields
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.comment_header
  font-size 0.8em
  color grey
  padding 2px 8px

.grid_container_column
  display grid
  grid-template-columns repeat(3, 1fr)
  grid-gap 5px
  padding 10px

.grid_container_row
  column-count 3
  column-gap 5px
  padding 10px

  +below(1100px)
    column-count 2

.comment_notes__inner
  margin-bottom 5px
  -webkit-column-break-inside avoid
  page-break-inside avoid
  break-inside avoid-column
  border 1px solid $darkgray
  border-radius 5px
  background $white

.comment_notes__body
  padding 8px
</style>
